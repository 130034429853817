<template>
  <Loading v-if="loading !== false" />
  <div class="lg:relative">
    <div class="lg:m-2 md:m-6 mx-3 mt-3">
      <div class="flex justify-between">
        <div
          :class="{
            'md:text-md text-sm top-rappers-text': true,
          }"
        >
          Top community
          <span class="text-white">{{ week }}</span>
        </div>
      </div>
      <router-link
        class="grid grid-cols-2 mt-3 gap-3 users items-center"
        v-for="(user, index) in topUser"
        :key="index"
        :to="{ name: 'CProfileWithSlug', params: { slug: user.slug } }"
      >
        <div class="items-center">
          <p class="pl-2 artist-list-name text-sm truncate mt-1">
            {{ index + 1 }}. {{ user.member_name }}
          </p>
        </div>
        <div
          :class="{
            'text-right w-full text-sm': true,
          }"
        >
          <p>{{ user.given_vote_count }} <span v-if="user.given_vote_count == 1">Vote</span><span v-else>Votes</span> </p>
        </div>
      </router-link>
    </div>
  </div>
</template> 

<script>
import API from "../../api/http";
import Loading from "../Shared/Loading";
export default {
  name: "Community",
  components: {
    Loading,
  },
  data() {
    return {
      media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
      // topUser: [],
      // loading: true,
    };
  },
  watch: {
    $route() {
      // this.loading = true;
      this.topUsers();
    },
  },
  computed: {
    topUser() {
      return this.$store.getters["Community/topUser"];
    },
    loading() {
      return this.$store.getters["Community/loading"];
    },
  },

  created() {
    this.topUsers(this.getTopArtistUrl);
  },
  methods: {
    topUsers() {
      API.get("top-members").then((response) => {
        // this.loading = false;
        // this.topUser = response.top_members;
        this.$store.commit("Community/SET_TOP_USER", response.top_members);
        this.$store.commit("Community/SET_LOADING", false);
      });
    },
    removeUser(e) {
      e, this.topUsers();
    },
  },
};
</script>
